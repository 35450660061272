import { WidgetInterface } from "../interfaces/widget.interface";

export const WidgetData: Array<WidgetInterface> = [
    {
        coinType: 'LOTTO',
        color: '',
        link: '',
        coinValue: ''
    },
    {
        coinType: 'GAME',
        color: '',
        link: '',
        coinValue: ''
    }
]
