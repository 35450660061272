<div class="side-nav">
    <div class="side-nav__navigation" (click)="navigatedAction.emit()">
        <a [routerLink]="dashboardLink" routerLinkActive="active" class="side-nav__navigation_item">
            <mat-icon svgIcon="dashboard" class="icon"></mat-icon> Dashboard
        </a>
        <a [routerLink]="lotteriesLink" routerLinkActive="active" class="side-nav__navigation_item">
            <mat-icon svgIcon="ticket" class="icon"></mat-icon> Lotteries
        </a>
        <a [routerLink]="swapLink" routerLinkActive="active" class="side-nav__navigation_item">
            <mat-icon svgIcon="swap" class="icon"></mat-icon> Swap
        </a>
        <a [routerLink]="governanceLink" routerLinkActive="active" class="side-nav__navigation_item">
            <mat-icon svgIcon="governance" class="icon"></mat-icon> Governance
        </a>
        <a [routerLink]="social" routerLinkActive="active" class="side-nav__navigation_item">
            <mat-icon svgIcon="globe" class="icon"></mat-icon> Social
        </a>
        <a [routerLink]="faqLink" routerLinkActive="active" class="side-nav__navigation_item">
            <mat-icon svgIcon="faq" class="icon"></mat-icon> FAQ
        </a>
    </div>
    <div class="side-nav__bottom">
        <div class="side-nav__widgets" *ngFor="let widget of widgetData">
            <app-widget [data]="widget"></app-widget>
        </div>
    </div>
</div>
