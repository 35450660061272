import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ProviderService } from './core/services/provider/provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public smallScreen = false;
  private destroy$ = new Subject();

  public showSideMenu = false;

  title = 'lotto';

  constructor(
    private readonly breakpointObserve: BreakpointObserver,
    private readonly _providerService: ProviderService
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserve.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.smallScreen = res.matches;
    });

    this._providerService.checkAccountReady();
  }

  public getShowMenuEvent() {
    this.showSideMenu = !this.showSideMenu;
  }

  public getNavigatedAction(): void {
    if (this.smallScreen) {
      this.showSideMenu = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

}
