import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Subject, takeUntil, timer, filter, switchMap } from 'rxjs';
import { ROUTER_LINKS } from 'src/app/core/enums/router-links.enum';
import { ProviderService } from 'src/app/core/services/provider/provider.service';
import { GameTokenService } from 'src/app/core/services/tokens/gameToken.service';
import { LottoTokenService } from 'src/app/core/services/tokens/lottoToken.service';
import { WidgetData } from '../../constants/widget.constant';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  @Output() navigatedAction = new EventEmitter();

  public widgetData = WidgetData;
  private destroy$ = new Subject();

  constructor(
    private lottoTokenService: LottoTokenService,
    private gameTokenService: GameTokenService,
    private providerService: ProviderService,
    private elementRef: ElementRef,
    private readonly _providerService: ProviderService,
  ) { }

  get dashboardLink(): string {
    return `/${ROUTER_LINKS.DASHBOARD}`;
  }

  get faqLink(): string {
    return `/${ROUTER_LINKS.FAQ}`;
  }

  get swapLink(): string {
    return `/${ROUTER_LINKS.SWAP}`;
  }

  get lotteriesLink(): string {
    return `/${ROUTER_LINKS.LOTTERIES}`;
  }

  get governanceLink(): string {
    return `/${ROUTER_LINKS.GOVERNANCE}`;
  }

  get social(): string {
    return `/${ROUTER_LINKS.SOCIAL}`;
  }

  ngOnInit(): void {
    this._providerService.accountReady$.pipe(
      filter(ready => !!ready),
      switchMap(() => timer(0, 30000)),
      takeUntil(this.destroy$),
    ).subscribe(async () => {
      this.getBalance();
    });
  }

  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.navigatedAction.emit();
    }
  }

  public async getBalance() {
    this.widgetData[0].coinValue = await this.lottoTokenService.getLottoBalance();
    this.widgetData[0].link = environment.scanUrl + '/address/' + environment.contracts.lottoToken;
    this.widgetData[1].coinValue = await this.gameTokenService.getGameBalance();
    this.widgetData[1].link = environment.scanUrl + '/address/' + environment.contracts.gameToken;
  }

  ngOnDestroy() {
    this.destroy$.next(null);
  }

}
