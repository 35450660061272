import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetComponent } from './widget.component';
import { IconModule } from 'src/app/core/icon/icon.module';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    WidgetComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    MatIconModule
  ],
  exports: [
    WidgetComponent
  ]
})
export class WidgetModule { }
