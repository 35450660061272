import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WidgetInterface } from '../../interfaces/widget.interface';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {

  @Input() data: WidgetInterface | null = null;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public getCoin(type: string): void {
    if (type === 'GAME') {
      this.router.navigateByUrl('/swap');
    }
  }

}
