<div class="sorting" *ngIf="short">
    <div class="sorting__title">Sort by:</div>
    <div class="sorting__item" [class.active]="activeSorting === item" *ngFor="let item of sortData" (click)="selectSorting(item)">
        <mat-icon  class="icon" svgIcon="sort-asc" *ngIf="activeSorting === item && !doublePressed"></mat-icon>
        <mat-icon  class="icon" svgIcon="sort-desc" *ngIf="activeSorting === item && doublePressed"></mat-icon>
        <mat-icon  class="icon" svgIcon="sort" *ngIf="activeSorting !== item"></mat-icon>
        {{item | titlecase}}
    </div>
</div>
<div class="sorting" [class.show-all]="showShortModal" *ngIf="!short">
    <div class="sorting__title" (click)="showShortModal = !showShortModal"><mat-icon  class="icon" svgIcon="sort"></mat-icon>Sort by</div>
    <ng-template [ngIf]="showShortModal" class="sorting__items">
        <div class="sorting__item_wrapper" *ngFor="let item of sortData">
            <div class="sorting__item" (click)="selectShortSorting(item, false)">
                {{item | titlecase}}: from low to high
            </div>
            <div class="sorting__item" (click)="selectShortSorting(item, true)">
                {{item | titlecase}}: from high to low
            </div>
        </div>
    </ng-template>
</div>