import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isSmallScreen = false;
  @Output() showMenu = new EventEmitter();
  @Input() showMenuStatus = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  public changeMenuStatus() {
    this.showMenu.emit();
  }
}
