import BigNumber from 'bignumber.js';

export type BigNumberValue = BigNumber.Value;

export function valueToBigNumber(amount: BigNumberValue): BigNumber {
  if (amount instanceof BigNumber) {
    return amount;
  }

  if (amount && typeof amount === 'object') {
    return new BigNumber((amount as BigNumber).toString());
  }

  return new BigNumber(amount || '0');
}

export function valueToWei(value: BigNumberValue, decimals: number): string {
  return valueToBigNumber(value).shiftedBy(decimals).toFixed(0);
}

export function valueFromWei(val: string, decimals: number = 18): string {
  return valueToBigNumber(val)
    .times(valueToBigNumber(10).pow(-decimals))
    .toString();
}
