import { Injectable } from '@angular/core';
import { ProviderService } from '../provider/provider.service';
import { gameTokenAbi } from '../../json/abis/gameToken.abi';
import { AbiItem } from 'web3-utils';
import { environment } from '../../../../environments/environment';
import { valueToBigNumber } from '../../helpers/bignumber';


@Injectable({
  providedIn: 'root'
})
export class GameTokenService {
  constructor(
    private provider: ProviderService
  ) {

  }

  public getGameContract() {
    return this.provider.getContract(gameTokenAbi as AbiItem[], environment.contracts.gameToken);
  }

  public async getGameBalance() {
    const contract = this.getGameContract()
    const balance = await contract.balanceOf(this.provider.getCurrentAddress());

    return this.provider.web3.utils.fromWei(balance, 'ether');
  }

  public async getAllowance(spender: string) {
    const contract = await this.getGameContract();

    const allowance = await contract.allowance(this.provider.getCurrentAddress(), spender);

    return this.provider.web3.utils.fromWei(allowance, 'ether');
  }

  public async getGameSupply() {
    const contract = this.getGameContract()
    const supply = await contract.totalSupply();

    return this.provider.web3.utils.fromWei(supply, 'ether');
  }

  public async approve(spender: string, gasFee = false) {
    const contract = await this.getGameContract();
    const approveAmount = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

    if (gasFee) {
      const gasPrice = await this.provider.getGasPrice();
      const gasLimit = this.provider.web3.utils.fromWei(
        (await contract.estimateGas.approve(spender, approveAmount)).toString(), 'gwei'
      );
      return valueToBigNumber(gasPrice).multipliedBy(gasLimit.toString()).toString()
    }

    return contract.approve(spender, approveAmount);
  }
}
