import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent implements OnInit, OnDestroy {
  @Input() sortData: string[];
  @Output() enabledSorting = new EventEmitter();
  @Input() short: boolean = false;

  private destroy$ = new Subject();

  public activeSorting = '';
  public doublePressed = false;
  public showShortModal = false;

  constructor(
    private breakpointObserve: BreakpointObserver
  ) { }

  ngOnInit(): void {

    this.breakpointObserve.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.short = !res.matches;
    });
  }

  public selectSorting(sorting: string) {
    if (this.activeSorting === sorting && this.doublePressed) {
      this.doublePressed = false;
      this.activeSorting = ''
    } else if (this.activeSorting === sorting) {
      this.doublePressed = true;
    } else {
      this.activeSorting = sorting;
      this.doublePressed = false;
    }

    this.enabledSorting.emit({
      sorting: this.activeSorting,
      asc: !this.doublePressed
    });
  }

  public selectShortSorting(sorting: string, way: boolean) {
    this.selectSorting(sorting);

    this.enabledSorting.emit({
      sorting: this.activeSorting,
      asc: way
    });

    this.showShortModal = false;
  }

  ngOnDestroy() {
    this.destroy$.next(null);
  }

}
