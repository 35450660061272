export const environment = {
  production: false,
  isMainnet: false,
  contracts: {
    gameToken: '0x2130fb765021811620C48a46eC26555700A954e2',
    lottoToken: '0x5fDeb369B89a52882d565734c869A1b082a905Ac',
    swapContract: '0xb3C5F1037409BBf7D074579F7De5DA0F2cE1Bf59',
    lotteryGameFactory: '0xEbDE9a4A8B0C31c575624b19f447C0ECeB475Bc5',
    rewardDistribution: '0x80Edd5A5A9cae8D9EA306d5f385935Ed10ce6e88',
    governanceStrategy: '0x9FE837C4503FEbc0D91c9f82A1bE60e619E52c44',
    executor: '0x7E5ecBB857801Fa7c8B93eE77814AD5C142F20F3',
    governance: '0xd9008Ce369A26464B1383Cb3eC3b93c9ebd40291',
    lotteryGame: '0x797F8cc80C76be21908F0cc332552E521015D104',
    generateBytecode: '0xd6aCa39d2b1260a909aC6665Cc4C549ADA07D0A9',
    dateTime: '0x91613A362182b7551E5947c9323Ad529D2e0AE45',
    router: '0x23251B4806eDb1D573Ed06333399935d2778Aa69',
    linkToken: '0x0b9d5D9136855f6FEc3c0993feE6E9CE8a297846',
  },
  scanUrl: 'https://avascan.info/blockchain/c',
  network: [
    {
      avalanche: {
        walletConnectProjectId: 'b51de7e7a93b21c88f7e07224f3aabf6',
        icon: 'assets/svg/network/avax-logo.svg',
        name: 'Avalanche Fuji Testnet',
        chainId: 43113,
        explorerUrl: 'https://subnets.avax.network',
        rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
        isActive: true
      }
    }
  ]
};
