<div class="widget" [ngClass]="{'blue': data?.coinType === 'LOTTO', 'purple': data?.coinType === 'GAME'}">
    <div class="widget__header">
        My {{data?.coinType}} Balance <a [href]="data?.link" target="_blank"> <mat-icon class="icon" svgIcon="link-arrow"></mat-icon></a>
    </div>
    <div class="widget__main">
        <img [src]="'../../../../assets/icons/' + data?.coinType?.toLowerCase() + '-icon.svg'"  class="icon" alt=""> {{data?.coinValue | number: '1.2'}}
    </div>
    <div class="widget__footer">
        <div class="btn btn-white" (click)="getCoin(data.coinType)">
            Get {{data?.coinType}}
        </div>
    </div>
</div>
