import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './side-nav.component';
import { RouterModule } from '@angular/router';
import { IconModule } from 'src/app/core/icon/icon.module';
import { MatIconModule } from '@angular/material/icon';
import { WidgetModule } from '../widget/widget.module';
import { LayoutModule } from '@angular/cdk/layout';



@NgModule({
  declarations: [
    SideNavComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    MatIconModule,
    WidgetModule,
    LayoutModule
  ],
  exports: [
    SideNavComponent
  ]
})
export class SideNavModule { }
