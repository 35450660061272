export const lottoTokenAbi = [{
  'inputs': [{
    'internalType': 'address',
    'name': '_lotteryGame',
    'type': 'address'
  }, { 'internalType': 'address', 'name': '_tokenRecipient', 'type': 'address' }],
  'stateMutability': 'nonpayable',
  'type': 'constructor'
}, {
  'anonymous': false,
  'inputs': [{
    'indexed': false,
    'internalType': 'address[]',
    'name': 'accounts',
    'type': 'address[]'
  }, { 'indexed': false, 'internalType': 'uint256', 'name': 'lastExistedGameId', 'type': 'uint256' }],
  'name': 'AddedToWhitelist',
  'type': 'event'
}, {
  'anonymous': false,
  'inputs': [{ 'indexed': true, 'internalType': 'address', 'name': 'owner', 'type': 'address' }, {
    'indexed': true,
    'internalType': 'address',
    'name': 'spender',
    'type': 'address'
  }, { 'indexed': false, 'internalType': 'uint256', 'name': 'value', 'type': 'uint256' }],
  'name': 'Approval',
  'type': 'event'
}, {
  'anonymous': false,
  'inputs': [{ 'indexed': false, 'internalType': 'address[]', 'name': 'accounts', 'type': 'address[]' }],
  'name': 'DeletedFromWhiteList',
  'type': 'event'
}, {
  'anonymous': false,
  'inputs': [{
    'indexed': true,
    'internalType': 'address',
    'name': 'previousOwner',
    'type': 'address'
  }, { 'indexed': true, 'internalType': 'address', 'name': 'newOwner', 'type': 'address' }],
  'name': 'OwnershipTransferred',
  'type': 'event'
}, {
  'anonymous': false,
  'inputs': [{ 'indexed': false, 'internalType': 'address', 'name': 'rewardPool', 'type': 'address' }],
  'name': 'RewardPoolChanged',
  'type': 'event'
}, {
  'anonymous': false,
  'inputs': [{ 'indexed': true, 'internalType': 'address', 'name': 'from', 'type': 'address' }, {
    'indexed': true,
    'internalType': 'address',
    'name': 'to',
    'type': 'address'
  }, { 'indexed': false, 'internalType': 'uint256', 'name': 'value', 'type': 'uint256' }],
  'name': 'Transfer',
  'type': 'event'
}, {
  'anonymous': false,
  'inputs': [{ 'indexed': false, 'internalType': 'address', 'name': 'account', 'type': 'address' }, {
    'indexed': false,
    'internalType': 'uint256',
    'name': 'newVotes',
    'type': 'uint256'
  }],
  'name': 'VotingPowerChanged',
  'type': 'event'
}, {
  'inputs': [],
  'name': 'GAMES_PER_EPOCH',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'PRECISION',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'TOTAL_ALLOCATED',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address[]', 'name': 'accounts', 'type': 'address[]' }],
  'name': 'addToWhitelist',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'owner', 'type': 'address' }, {
    'internalType': 'address',
    'name': 'spender',
    'type': 'address'
  }],
  'name': 'allowance',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'spender', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'amount',
    'type': 'uint256'
  }],
  'name': 'approve',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'account', 'type': 'address' }],
  'name': 'balanceOf',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'name': 'balances',
  'outputs': [{ 'internalType': 'uint256', 'name': 'lastGameId', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': 'balance',
    'type': 'uint256'
  }, { 'internalType': 'uint256', 'name': 'at', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'account', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'amount',
    'type': 'uint256'
  }], 'name': 'burn', 'outputs': [], 'stateMutability': 'nonpayable', 'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'name': 'countSnapshots',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'decimals',
  'outputs': [{ 'internalType': 'uint8', 'name': '', 'type': 'uint8' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'spender', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'subtractedValue',
    'type': 'uint256'
  }],
  'name': 'decreaseAllowance',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address[]', 'name': 'accounts', 'type': 'address[]' }],
  'name': 'deleteFromWhitelist',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'name': 'epochs',
  'outputs': [{ 'internalType': 'uint256', 'name': 'totalFees', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': 'minParticipationFee',
    'type': 'uint256'
  }, { 'internalType': 'uint256', 'name': 'firstLotteryId', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': 'lastLotteryId',
    'type': 'uint256'
  }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'epochsCount',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': '_participants', 'type': 'uint256' }, {
    'internalType': 'address[]',
    'name': '_winnerAddresses',
    'type': 'address[]'
  }, { 'internalType': 'address', 'name': '_marketingAddress', 'type': 'address' }, {
    'internalType': 'uint256[]',
    'name': '_winningPrizeValues',
    'type': 'uint256[]'
  }, { 'internalType': 'uint256', 'name': '_marketingFeeValue', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': '_rewards',
    'type': 'uint256'
  }],
  'name': 'finishLottery',
  'outputs': [{
    'components': [{
      'internalType': 'uint256',
      'name': 'id',
      'type': 'uint256'
    }, { 'internalType': 'uint256', 'name': 'participationFee', 'type': 'uint256' }, {
      'internalType': 'uint256',
      'name': 'startedAt',
      'type': 'uint256'
    }, { 'internalType': 'uint256', 'name': 'finishedAt', 'type': 'uint256' }, {
      'internalType': 'uint256',
      'name': 'participants',
      'type': 'uint256'
    }, { 'internalType': 'address[]', 'name': 'winners', 'type': 'address[]' }, {
      'internalType': 'uint256',
      'name': 'epochId',
      'type': 'uint256'
    }, { 'internalType': 'uint256[]', 'name': 'winningPrize', 'type': 'uint256[]' }, {
      'internalType': 'uint256',
      'name': 'rewards',
      'type': 'uint256'
    }, { 'internalType': 'address', 'name': 'rewardPool', 'type': 'address' }, {
      'internalType': 'bool',
      'name': 'isActive',
      'type': 'bool'
    }], 'internalType': 'struct ILotteryToken.Lottery', 'name': 'finishedLotteryGame', 'type': 'tuple'
  }],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'lotteryId', 'type': 'uint256' }],
  'name': 'forceFinish',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'id_', 'type': 'uint256' }],
  'name': 'getLottery',
  'outputs': [{
    'components': [{
      'internalType': 'uint256',
      'name': 'id',
      'type': 'uint256'
    }, { 'internalType': 'uint256', 'name': 'participationFee', 'type': 'uint256' }, {
      'internalType': 'uint256',
      'name': 'startedAt',
      'type': 'uint256'
    }, { 'internalType': 'uint256', 'name': 'finishedAt', 'type': 'uint256' }, {
      'internalType': 'uint256',
      'name': 'participants',
      'type': 'uint256'
    }, { 'internalType': 'address[]', 'name': 'winners', 'type': 'address[]' }, {
      'internalType': 'uint256',
      'name': 'epochId',
      'type': 'uint256'
    }, { 'internalType': 'uint256[]', 'name': 'winningPrize', 'type': 'uint256[]' }, {
      'internalType': 'uint256',
      'name': 'rewards',
      'type': 'uint256'
    }, { 'internalType': 'address', 'name': 'rewardPool', 'type': 'address' }, {
      'internalType': 'bool',
      'name': 'isActive',
      'type': 'bool'
    }], 'internalType': 'struct ILotteryToken.Lottery', 'name': '', 'type': 'tuple'
  }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'getTotalSupply',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'account', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'blockTimestamp',
    'type': 'uint256'
  }],
  'name': 'getVotingPowerAt',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'spender', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'addedValue',
    'type': 'uint256'
  }],
  'name': 'increaseAllowance',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'isTransferLocked',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'user_', 'type': 'address' }],
  'name': 'isWhitelisted',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'lastEpoch',
  'outputs': [{
    'components': [{
      'internalType': 'uint256',
      'name': 'totalFees',
      'type': 'uint256'
    }, { 'internalType': 'uint256', 'name': 'minParticipationFee', 'type': 'uint256' }, {
      'internalType': 'uint256',
      'name': 'firstLotteryId',
      'type': 'uint256'
    }, { 'internalType': 'uint256', 'name': 'lastLotteryId', 'type': 'uint256' }],
    'internalType': 'struct ILotteryToken.Epoch',
    'name': 'epoch',
    'type': 'tuple'
  }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'lastLottery',
  'outputs': [{
    'components': [{
      'internalType': 'uint256',
      'name': 'id',
      'type': 'uint256'
    }, { 'internalType': 'uint256', 'name': 'participationFee', 'type': 'uint256' }, {
      'internalType': 'uint256',
      'name': 'startedAt',
      'type': 'uint256'
    }, { 'internalType': 'uint256', 'name': 'finishedAt', 'type': 'uint256' }, {
      'internalType': 'uint256',
      'name': 'participants',
      'type': 'uint256'
    }, { 'internalType': 'address[]', 'name': 'winners', 'type': 'address[]' }, {
      'internalType': 'uint256',
      'name': 'epochId',
      'type': 'uint256'
    }, { 'internalType': 'uint256[]', 'name': 'winningPrize', 'type': 'uint256[]' }, {
      'internalType': 'uint256',
      'name': 'rewards',
      'type': 'uint256'
    }, { 'internalType': 'address', 'name': 'rewardPool', 'type': 'address' }, {
      'internalType': 'bool',
      'name': 'isActive',
      'type': 'bool'
    }], 'internalType': 'struct ILotteryToken.Lottery', 'name': 'lottery', 'type': 'tuple'
  }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'lockTransfer',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'lotteriesCount',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'lotteryGame',
  'outputs': [{ 'internalType': 'contract ILotteryGame', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'name',
  'outputs': [{ 'internalType': 'string', 'name': '', 'type': 'string' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'owner',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'name': 'participants',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'from', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': 'count',
    'type': 'uint256'
  }],
  'name': 'participantsBalances',
  'outputs': [{
    'internalType': 'address[]',
    'name': 'participantAddresses',
    'type': 'address[]'
  }, { 'internalType': 'uint256[]', 'name': 'participantBalances', 'type': 'uint256[]' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'participantsCount',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'renounceOwnership',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'rewardPool',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'lotteryGame_', 'type': 'address' }],
  'name': 'setLotteryGame',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': '_rewardPool', 'type': 'address' }],
  'name': 'setRewardPool',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': '',
    'type': 'uint256'
  }],
  'name': 'snapshots',
  'outputs': [{ 'internalType': 'uint256', 'name': 'blockTimestamp', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': 'votes',
    'type': 'uint256'
  }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': '_participationFee', 'type': 'uint256' }],
  'name': 'startLottery',
  'outputs': [{
    'components': [{
      'internalType': 'uint256',
      'name': 'id',
      'type': 'uint256'
    }, { 'internalType': 'uint256', 'name': 'participationFee', 'type': 'uint256' }, {
      'internalType': 'uint256',
      'name': 'startedAt',
      'type': 'uint256'
    }, { 'internalType': 'uint256', 'name': 'finishedAt', 'type': 'uint256' }, {
      'internalType': 'uint256',
      'name': 'participants',
      'type': 'uint256'
    }, { 'internalType': 'address[]', 'name': 'winners', 'type': 'address[]' }, {
      'internalType': 'uint256',
      'name': 'epochId',
      'type': 'uint256'
    }, { 'internalType': 'uint256[]', 'name': 'winningPrize', 'type': 'uint256[]' }, {
      'internalType': 'uint256',
      'name': 'rewards',
      'type': 'uint256'
    }, { 'internalType': 'address', 'name': 'rewardPool', 'type': 'address' }, {
      'internalType': 'bool',
      'name': 'isActive',
      'type': 'bool'
    }], 'internalType': 'struct ILotteryToken.Lottery', 'name': '_lotteryGame', 'type': 'tuple'
  }],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'symbol',
  'outputs': [{ 'internalType': 'string', 'name': '', 'type': 'string' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'totalSupply',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'to', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'amount',
    'type': 'uint256'
  }],
  'name': 'transfer',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'from', 'type': 'address' }, {
    'internalType': 'address',
    'name': 'to',
    'type': 'address'
  }, { 'internalType': 'uint256', 'name': 'amount', 'type': 'uint256' }],
  'name': 'transferFrom',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'newOwner', 'type': 'address' }],
  'name': 'transferOwnership',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [],
  'name': 'unlockTransfer',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'inputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'name': 'whitelistedAccounts',
  'outputs': [{ 'internalType': 'bool', 'name': 'isWhitelisted', 'type': 'bool' }, {
    'internalType': 'uint256',
    'name': 'lastParticipatedGameId',
    'type': 'uint256'
  }],
  'stateMutability': 'view',
  'type': 'function'
}];
