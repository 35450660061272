import { Injectable } from '@angular/core';
import Web3 from 'web3';
import { BehaviorSubject, delay, EMPTY, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { createWeb3Modal, defaultConfig, Web3Modal } from '@web3modal/ethers5'
import { Contract, ContractInterface, ethers } from 'ethers';
import { environment } from '../../../../environments/environment';
import { first, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  public accountReady$ = new BehaviorSubject(false);
  public modal: Web3Modal = null;
  public get web3(): Web3 {
    return new Web3(this.modal?.getWalletProvider());
  }

  constructor() {
    // 1. Get projectId at https://cloud.walletconnect.com
    const projectId = environment.network[0].avalanche.walletConnectProjectId;

    // 2. Set chains
    const network = {
      chainId: environment.network[0].avalanche.chainId,
      name: environment.network[0].avalanche.name,
      currency: 'AVAX',
      explorerUrl: environment.network[0].avalanche.explorerUrl,
      rpcUrl: environment.network[0].avalanche.rpcUrl, // 'https://avalanche.drpc.org'
    }

    // 3. Create your application's metadata object
    const metadata = {
      name: 'Lotto',
      description: 'Lotto',
      url: location.origin, // url must match your domain & subdomain
      icons: ['https://avatars.mywebsite.com/']
    }

    // 4. Create Ethers config
    const ethersConfig = defaultConfig({
      /*Required*/
      metadata,

      /*Optional*/
      enableEIP6963: true, // true by default
      enableInjected: false, // true by default
      enableCoinbase: false, // true by default
      rpcUrl: 'https://avalanche.drpc.org', // used for the Coinbase SDK
      defaultChainId: 1, // used for the Coinbase SDK
    })

    // 5. Create a Web3Modal instance
    this.modal = createWeb3Modal({
      ethersConfig,
      chains: [network],
      projectId,
      enableAnalytics: false, // Optional - defaults to your Cloud configuration
      enableOnramp: false, // Optional - false as default
      featuredWalletIds: [
        // https://explorer.walletconnect.com/?type=wallet
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // metamask
      ]
    })
  }

  public checkAccountReady() {
    this.modal.subscribeProvider(({ provider, providerType, address, error, chainId, isConnected }) => {
      this.accountReady$.next(isConnected && !!address);
    });
  }

  public getCurrentAddress() {
    return this.modal.getAddress();
  }

  public getContract(abi: ContractInterface, address: string): Contract  {
    const walletProvider = this.modal.getWalletProvider();
    let provider: ethers.providers.Provider;
    if (walletProvider) {
      provider = new ethers.providers.Web3Provider(walletProvider);
    } else {
      provider = new ethers.providers.JsonRpcProvider(environment.network[0].avalanche.rpcUrl);
    }
    const signerOrProvider = walletProvider ? (provider as any).getSigner() : provider;
    return new ethers.Contract(address, abi, signerOrProvider);
  }

  public async getGasPrice() {
    const walletProvider = this.modal.getWalletProvider();
    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const gasPrice = await ethersProvider.getGasPrice();

    return Web3.utils.fromWei(gasPrice.toString(), 'gwei');
  }

  // verify if user is connected, if not - show Wallet Popup, when popup closed - check if connected - if not - skip it
  public connectAndVerify() {
    return this.accountReady$.pipe(
      first(),
      switchMap(ready => {
        if (ready) {
          return of(true);
        }

        this.modal.open();
        const modalProcessed = new Subject();
        let processing = true;
        this.modal.subscribeState(newState => {
          if (!processing) {
            return;
          }
          if (!newState.open) {
            processing = false;
            modalProcessed.next(true);
          }
        });

        return modalProcessed.pipe(
          delay(50),
          switchMap(() => this.accountReady$.pipe(first())),
          switchMap(ready => {
            if (ready) {
              return of(true);
            }
            return EMPTY;
          }),
        )
      }),
    );
  }
}
