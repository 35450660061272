<div class="header">
    <div class="header__logo">
        <div class="header__logo_logo-wrapper">
            <mat-icon svgIcon="burger-menu" class="burger" *ngIf="isSmallScreen" (click)="changeMenuStatus()" [class.active]="showMenuStatus"></mat-icon>
            <mat-icon svgIcon="logo"></mat-icon>
        </div>

      <w3m-button balance="hide"></w3m-button>
    </div>
</div>
