import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTER_LINKS } from './core/enums/router-links.enum';

const routes: Routes = [
  { path: '', redirectTo: `${ROUTER_LINKS.DASHBOARD}`, pathMatch: 'full' },
  {
    path: `${ROUTER_LINKS.DASHBOARD}`,
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: `${ROUTER_LINKS.FAQ}`,
    loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: `${ROUTER_LINKS.GOVERNANCE}`,
    loadChildren: () => import('./modules/governance/governance.module').then(m => m.GovernanceModule)
  },
  {
    path: `${ROUTER_LINKS.LOTTERIES}`,
    loadChildren: () => import('./modules/lotteries/lotteries.module').then(m => m.LotteriesModule)
  },
  {
    path: `${ROUTER_LINKS.SOCIAL}`,
    loadChildren: () => import('./modules/social/social.module').then(m => m.SocialModule)
  },
  {
    path: `${ROUTER_LINKS.SWAP}`,
    loadChildren: () => import('./modules/swap/swap.module').then(m => m.SwapModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
